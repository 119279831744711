import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  Content,
  ImageCollection,
  CustomerReviewModal,
  Contact,
} from "@bluefin/components";
import { Image, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage, getPageGalleryImages } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={""}
              tagline={
                <Image
                  size={"big"}
                  src={
                    "https://fisherman.gumlet.io/public/thegrilleatblackhawk/Grille_at_Blackhawk_Logo_White.png"
                  }
                />
              }
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[
                {
                  text: "ChowNow",
                  internal: false,
                  link: "https://direct.chownow.com/order/25630/locations",
                },
                {
                  text: "DoorDash",
                  internal: false,
                  link: "https://www.doordash.com/store/the-grille-at-blackhawk-danville-1351775/?utm_medium=website&utm_source=partner-link",
                },
                {
                  text: "GrubHub",
                  link: "https://www.grubhub.com/restaurant/the-grille-at-blackhawk-3540-blackhawk-plaza-cir-blackhawk/2513220?irgwc=1&mp=Bing%20Rebates%20by%20Microsoft&utm_source=Bing%20Rebates%20by%20Microsoft&utm_medium=content_paid&utm_campaign=growth_radius-network&utm_channel=other&clickid=3FhQrcw8UxyLT2y05-R4sULoUkEy72yhzWtnUw0&SharedID=&chiri_umami_homepage_loggedout_headerBanner=xyz&blockNavi=true",
                  internal: false,
                },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
          subfooter={false}
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Content>
                <Content.Markup>
                  {fishermanBusiness.aboutMarkdown}
                </Content.Markup>
              </Content>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <h1>
                East Bay Eats with Lisa Doyle Ep. 9 - The Grille at Blackhawk
              </h1>
              <iframe
                width={"80%"}
                height={"600"}
                src={"https://www.youtube.com/embed/k0-u3NSW0WU"}
                frameborder={"0"}
                allow={
                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                }
                allowfullscreen={true}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                images={getPageGalleryImages({
                  options: fishermanBusinessWebsitePage.components,
                })}
                as={"gallery"}
                centered={true}
                cutUnevenRows={false}
                disable={"none"}
                header={"Photos"}
                numberOfRowDisplayItems={3}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={"restaurant"}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <Contact
                businessName={fishermanBusiness.name}
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                hours={fishermanBusiness.primaryLocation.hours}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      aboutMarkdown
      _id
      primaryLocation {
        phoneNumber
        hours {
          open
          close
          label
          day
        }
        street
        city
        state
        zipCode
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Home" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
              fluid(maxWidth: 720) {
                aspectRatio
                base64
                src
                srcSet
              }
            }
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
